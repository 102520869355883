@import 'param';
@import 'base'; // https://raw.githubusercontent.com/jasonlong/cayman-theme/

/* Navigation */

.navigation {
  text-align: center;
  padding: 1rem;

  @include small {
    padding: 0.4rem;
  }
}

nav {
  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 2px;

      a {
        // color: white;
        padding: 6px;
        border-bottom: 2px solid transparent;

        &:hover {
          text-decoration: none;
        }

        &:not(.page-current):hover {
          border-bottom-color: rgba(255, 255, 255, 0.4);
        }

        &.page-current {
          border-bottom-color: white;
        }
      }
    }
  }
}

/* Content */

.site-title {
  margin-top: 0;
  margin-bottom: 0.1rem;

  @include large {
    font-size: 2.25rem;
  }

  @include medium {
    font-size: 2.25rem;
  }

  @include small {
    font-size: 1.75rem;
  }

  font-weight: normal;
  font-size: 1.75rem;

  a {
    // color: white;
  }
}

.site-description {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-weight: normal;
  opacity: 0.9;

  @include large {
    font-size: 1.25rem;
  }

  @include medium {
    font-size: 1.15rem;
  }

  @include small {
    font-size: 1rem;
  }

  @include large {
    font-size: 1.15rem;
  }
}

.post-title {
  margin-bottom: 1.2rem;
}

.post-subtitle {
  display: block;
  // color: #ffffff;
  opacity: 0.8;

  a {
    // color: #ffffff;
    opacity: 1;
  }
}

.post-content {
  padding-top: 0.4rem;
}

.item-title {
  margin-bottom: 0.4rem;
}

.item-subtitle {
  display: block;
  margin-bottom: 1rem;
  // color: #666666;
  font-size: small;
}

.keywords {
}

.screenshots {
  display: grid;

  a {
    margin: auto;

    img {
      border: 1px solid #999;
      margin: 8px;
      max-width: calc(100% - 16px);
      max-height: calc(100vh - 16px);

      &[src*='.full.'] {
        max-height: none;
      }
    }
  }
}
