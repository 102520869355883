.gist {
  &,
  * {
    border: 0;
    border-radius: 0 !important;
  }
  .blob-num {
    border: 0;
  }
}
