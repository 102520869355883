/* Parameters */

// Breakpoints
$large-breakpoint: 64em !default;
$medium-breakpoint: 42em !default;
// Text
$body-text-color: #606c71 !default;
$body-link-color: #1e6bb8 !default;
$blockquote-text-color: #819198 !default;
// Code
$code-background-color: #f3f6fa !default;
$code-text-color: #567482 !default;
// Borders
$border-color: #dce6f0 !default;
$table-border-color: #e9ebec !default;
$hr-border-color: #eff0f1 !default;

/* Mixins */

@mixin large {
  @media screen and (min-width: #{$large-breakpoint}) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: #{$medium-breakpoint}) and (max-width: #{$large-breakpoint}) {
    @content;
  }
}

@mixin small {
  @media screen and (max-width: #{$medium-breakpoint}) {
    @content;
  }
}

//
//  Function to create an optimized svg url
//  Version: 1.0.6
@function svg-url($svg) {
  //
  //  Add missing namespace
  //
  @if not str-index($svg, xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '&', '%26');
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url('data:image/svg+xml,#{$encoded}');
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
    $string
  );
}

/* Common */

html {
  height: 100%;
  overflow: auto;
  overflow: overlay;
}

::-webkit-scrollbar-track {
  background-color: rgba($secondary-color, 0.2);
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: darken($primary-color, 6%);
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba($secondary-color, 0.2);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  // font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: 'Source Code Pro', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
  color: $body-text-color;
}

a {
  color: $body-link-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.external:after,
  &[target='_blank']:after {
    content: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#333333" d="M14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3m-2 16H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7h-2v7z"/></svg>'
    );
    vertical-align: middle;
    margin: 0 0 0 2px;
    line-height: 0;
  }
}

.header {
  text-align: center;
  @include small {
    margin: 2rem 1rem 1rem;
  }
  @include medium {
    margin: 2rem 4rem 1rem;
  }
  @include large {
    margin: 3rem 4rem 1.5rem;
  }

  a.external:after,
  a[target='_blank']:after {
    filter: invert(100%) opacity(80%);
  }
}

.main {
  word-wrap: break-word;
  // > :first-child {
  //   margin-top: 0;
  // }
  @include large {
    max-width: 64rem;
    padding: 1.5rem 2rem 6rem;
    margin: 0 auto; // font-size: 1.1rem;
  }
  @include medium {
    padding: 1rem 2rem 4rem; // font-size: 1.1rem;
  }
  @include small {
    padding: 1rem 1.6rem 1.6rem; // font-size: 1rem;
  }
  img {
    max-width: 100%;
    text-align: center;
    display: block;
    margin: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: normal;
  }
  article {
    // margin-top: 1rem;
  }
  p {
    margin-bottom: 1em;
  }
  code {
    padding: 2px 4px;
    font-family: 'Source Code Pro', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 0.9rem;
    color: $code-text-color;
    background-color: $code-background-color;
  }
  pre {
    padding: 0.8rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font: 1rem Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    color: $code-text-color;
    word-wrap: normal;
    background-color: $code-background-color;
    border: solid 1px $border-color;
    > code {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      color: $code-text-color;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0;
    }
  }
  .highlight {
    margin-bottom: 1rem;
    pre {
      margin-bottom: 0;
      word-break: normal;
    }
  }
  .highlight pre,
  pre {
    padding: 0.8rem;
    overflow: auto;
    overflow: overlay;
    font-size: 0.9rem;
    line-height: 1.45;
    -webkit-overflow-scrolling: touch;
  }
  pre code,
  pre tt {
    display: inline;
    max-width: initial;
    padding: 0;
    margin: 0;
    overflow: initial;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    &:before,
    &:after {
      content: normal;
    }
  }
  ul,
  ol {
    margin-top: 0;
  }
  blockquote {
    padding: 0 1rem;
    margin-left: 0;
    color: $blockquote-text-color;
    border-left: 0.3rem solid $border-color;
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
  table {
    display: block;
    width: 100%;
    overflow: auto;
    overflow: overlay;
    word-break: normal;
    word-break: keep-all; // For Firefox to horizontally scroll wider tables.
    -webkit-overflow-scrolling: touch;
    th {
      font-weight: bold;
    }
    th,
    td {
      padding: 0.5rem 1rem;
      border: 1px solid $table-border-color;
    }
  }
  dl {
    padding: 0;
    dt {
      padding: 0;
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: bold;
    }
    dd {
      padding: 0;
      margin-bottom: 1rem;
    }
  }
  hr {
    height: 2px;
    padding: 0;
    margin: 1rem 0;
    background-color: $hr-border-color;
    border: 0;
  }

  ul.listing.work {
    list-style-type: none;

    @include small {
      padding-left: 1rem;
    }

    li :first-child {
      font-size: smaller;
      margin-right: 4px;
    }
  }
  ul.listing:not(.work) {
    list-style-type: none;

    @include small {
      padding-left: 1rem;
    }

    li :first-child {
      display: block;
      font-size: smaller;
      margin-right: 4px;
    }

    li :last-child {
      display: block;
      margin-top: 0.2em;
      margin-bottom: 1em;
    }
  }
}
